* {
  margin: 0;
}
.app {
  // background-color: rgb(9, 28, 37);
  font-family: "Roboto", sans-serif;
  background: rgb(13, 13, 13);
  margin: 0;
  overflow-x: hidden;
}

.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 400;
}

.banner--fadeBottom {
  height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(13, 13, 13, 0.8),
    rgb(221, 28, 28)
  );
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-8deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
.app {
  animation: transitionIn 3.5s;
}
